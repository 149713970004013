import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const AllGauges = Loadable(lazy(() => import("./AllGauges")));
const TemperatureGauge = Loadable(lazy(() => import("./TemperatureGauge")));
const SimpleGauge = Loadable(lazy(() => import("./SimpleGauge")));
const GradientWithArrowGauge = Loadable(lazy(() => import("./GradientWithArrowGauge")));
const GaugeWithBlob = Loadable(lazy(() => import("./GaugeWithBlob")));

const GaugeRoutes = [    
    {
        path: 'gaugeChartsComponents/GaugeWithBlob',
        element: <GaugeWithBlob />
    }, 
    {
        path: 'gaugeChartsComponents/GradientWithArrowGauge',
        element: <GradientWithArrowGauge />
    }, 
    {
        path: 'gaugeChartsComponents/SimpleGauge',
        element: <SimpleGauge />
    }, 
    {
        path: 'gaugeChartsComponents/TemperatureGauge',
        element: <TemperatureGauge />
    },  
    {
        path: 'gaugeChartsComponents/AllGauges',
        element: <AllGauges />
    },
]

export default GaugeRoutes
