
import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const User = Loadable(lazy(() => import("./Users")));
const EditUser = Loadable(lazy(() => import("./User/EditUser")));

const UsersRoutes = [
    {
        path: '/users/Users',
        element: <User />,
    },
    {
        path: '/users/edit',
        element: <EditUser />
    },
    {
        path: '/users/add',
        element: <EditUser />
    }
];

export default UsersRoutes;