import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Conversations = Loadable(lazy(() => import("./Conversations")));
const AgentConversations = Loadable(lazy(() => import("./AgentConversations")));

const conversationsRoutes = [
    {
        path: '/conversations/conversations',
        element: <Conversations />,
    },
    {
        path: '/conversations/agentConversations',
        element: <AgentConversations />,
    },
]
export default conversationsRoutes
