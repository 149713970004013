import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import Auth from './redux-chat/auth/reducers';
import Chat from './redux-chat/chat/reducers';
import Layout from './redux-chat/layout/reducer';

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    auth: Auth,
    chat: Chat,
    layout: Layout
})

export default RootReducer
