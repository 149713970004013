import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const SendEmail = Loadable(lazy(() => import("./SendEmailView")));

const EmailsRoutes = [
    {
        path: '/emails/SendEmail',
        element: <SendEmail />,
    }
]

export default EmailsRoutes
