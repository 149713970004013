import '../fake-db'
import React from 'react'
import { Store } from './redux/Store'
import { Provider } from 'react-redux'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/components'
import { useRoutes } from 'react-router-dom'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import '../styles/custom/k360.css';
import '../styles/custom/dashboard.css';
import '../styles/custom/positionings.css';
import '../styles/custom/colors.css';
import { useEffect } from 'react'
import i18n from "i18next";


const App = () => {
    const all_pages = useRoutes(AllPages())

    useEffect(() => {
        let language = localStorage.getItem("language");
        if(language == undefined){
            language = 'sp';
            localStorage.setItem('language', language)
        }
        i18n.changeLanguage(language);

      }, [])
    
    
    return (
        <Provider store={Store}>
            <SettingsProvider className='font-Source-Sans-Pro'>
                <MatxTheme>
                    <AuthProvider>{all_pages}</AuthProvider>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
