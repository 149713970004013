
import Swal from "sweetalert2";

const resolveUrl = () => document.domain !== 'localhost' ? '' : process.env.REACT_APP_API_URL;
const resolveViciUrl = () => process.env.REACT_APP_API_VICIDIAL_NET;

// const resolveUrlConcierge = () => document.domain !== 'localhost' ? '' : process.env.REACT_APP_API_URL;
export const apiGetConciergeBotURl = `${resolveUrl()}/api/General/GetConciergeBotURl`;
export const viciBaseUrl = process.env.REACT_APP_VICIDIAL_WEB;


// export const apiCONCIERGE_BOT_URl = getConciergeBotURl().then;

export const viciPhone = process.env.REACT_APP_VICIPHONE_URL
export const viciWebServerOnly= process.env.REACT_APP_VICIWEB


//#region auth
export const apiLogin = `${resolveUrl()}/api/login`;
export const apiProfile = `${resolveUrl()}/api/profile`;
export const apiLogout = `${resolveUrl()}/api/logout`;
export const apiViciLogin = `${resolveUrl()}/api/ExternLoginVicidial`;
export const apiLoginVicidial = `${resolveUrl()}/api/Users/LoginVicidial`;

export const apiSendResetPasswordEmail = `${resolveUrl()}/api/Session/SendResetPasswordEmail`;
export const apiResetPassword = `${resolveUrl()}/api/Session/ResetPassword`;
export const apiValidateUrlToResetPassword = `${resolveUrl()}/api/Session/ValidateUrlToResetPassword`;
export const apiRefreshToken = `${resolveUrl()}/api/Session/RefreshToken`; 
export const apiUpdUserBusy = `${resolveUrl()}/api/Session/UpdUserBusy`; 
export const apiUpdDISPO = `${resolveUrl()}/api/Session/UpdDISPO`; 
export const apiDisconnectUser = `${resolveUrl()}/api/Session/DisconnectUser`; 
export const apiupdFechaLastTimeActive = `${resolveUrl()}/api/Session/updFechaLastTimeActive`;
//export const apiUpdateAgentStateId = `${resolveUrl()}/api/Session/UpdateAgentStateId`;  
export const apiValidateStateUser = `${resolveUrl()}/api/Session/ValidateStateUser`;
export const apiUpdLogOutVici = `${resolveUrl()}/api/Session/UpdLogOutVici`; 


export const apiBlackListMail = `${resolveUrl()}/api/Session/BlackListMail`;
//#endregion auth

//#region IVR
export const apiGetIvrsMain = `${resolveUrl()}/api/Settings/Ivrs/GetIvrsMainList`;
export const apiAddIvrsMain = `${resolveUrl()}/api/Settings/IVRs/AddIvrsMain`;
export const apiUpdateIvrsMain = `${resolveUrl()}/api/Settings/IVRs/UpdateIvrsMain`;
export const apiDeleteIvrMain = `${resolveUrl()}/api/Settings/Ivrs/DeleteIvrMain`;
export const apiGetIvrsList = `${resolveUrl()}/api/Settings/Ivrs/GetIvrsList`;
export const apiGetIvrsListWS = `${resolveUrl()}/api/Settings/Ivrs/GetIvrsListWS`;
export const apiGetIvrsSurveyList = `${resolveUrl()}/api/Settings/Ivrs/GetIvrsSurveyList`;
export const apiGetConfExtenFBX = `${resolveUrl()}/api/Settings/Ivrs/GetConfExtenFBX`;
export const apiGetDidExtension = `${resolveUrl()}/api/attentionline/GetDidExtension`;
export const apiGetAttentionLineListByCampiangId = `${resolveUrl()}/api/attentionline/GetAttentionLineListByCampiangId`;
export const apiGetChannelsList = `${resolveUrl()}/api/attentionline/GetChannelsList`;

export const apiGetPrefixNumberList = `${resolveUrl()}/api/Campaigns/GetPrefixNumberList`;

export const apiGetIvr = `${resolveUrl()}/api/Settings/Ivrs/GetIvr`;
export const apiAddIvrs = `${resolveUrl()}/api/Settings/Ivrs/AddIvrs`;
export const apiUpdateIvr = `${resolveUrl()}/api/Settings/Ivrs/UpdateIvr`;
export const apiDeleteIvr = `${resolveUrl()}/api/Settings/Ivrs/DeleteIvr`;
export const apiAplicarIvr = `${resolveUrl()}/api/Settings/Ivrs/AplicarIvr`;
export const apiCopyIvr = `${resolveUrl()}/api/Settings/Ivrs/copyIvr`;

export const apiGetTruncals = `${resolveUrl()}/api/Settings/Truncals/GetTruncals`;
export const apiAddTruncal = `${resolveUrl()}/api/Settings/Truncals/AddTruncal`;
export const apiUpdateTruncal = `${resolveUrl()}/api/Settings/Truncals/UpdateTruncal`;
export const apiDeleteTruncal = `${resolveUrl()}/api/Settings/Truncals/DeleteTruncal`;

export const apiGetPolls = `${resolveUrl()}/api/Settings/GetPolls`;
export const apiAddPoll = `${resolveUrl()}/api/Settings/AddPoll`;
export const apiUpdatePoll = `${resolveUrl()}/api/Settings/UpdatePoll`;
export const apiDeletePoll = `${resolveUrl()}/api/Settings/DeletePoll`;
export const apiGetPollQuestions = `${resolveUrl()}/api/Settings/GetPollQuestions`;

export const apiGetListPollAnswersGroup = `${resolveUrl()}/api/Settings/GetListPollAnswersGroup`;
export const apiGetPollAnswersGroup = `${resolveUrl()}/api/Settings/GetPollAnswersGroup`;
export const apiAddPollAnswersGroup = `${resolveUrl()}/api/Settings/AddPollAnswersGroup`;
export const apiActivePollAnswersGroup = `${resolveUrl()}/api/Settings/ActivePollAnswersGroup`;
export const apiDeletePollAnswersGroup = `${resolveUrl()}/api/Settings/DeletePollAnswersGroup`;

export const apiGetPollAnswersCatalog = `${resolveUrl()}/api/Settings/GetPollAnswersCatalog`;
export const apiAddPollAnswersCatalog = `${resolveUrl()}/api/Settings/AddPollAnswersCatalog`;
export const apiDeletePollAnswersCatalog = `${resolveUrl()}/api/Settings/DeletePollAnswersCatalog`;
export const apiGetSummary = `${resolveUrl()}/api/Dashboard/GetSummary`;
export const apiGetSummaryFiltered = `${resolveUrl()}/api/Dashboard/GetSummaryFiltered`;
export const apiGruopConversationsByChannel = `${resolveUrl()}/api/Dashboard/GruopConversationsByChannel`;
export const apiGetDashboardInformation = `${resolveUrl()}/api/Dashboard/GetDashboardInformation`;

//Feelings Dashboard
export const apiGetInformationIncidentGraph = `${resolveUrl()}/api/Dashboard/GetInformationIncidentGraph`;
export const apiGetInformationTrendGraph = `${resolveUrl()}/api/Dashboard/GetInformationTrendGraph`;
export const apiGetInformationIncidentPercentageGraph = `${resolveUrl()}/api/Dashboard/GetInformationIncidentPercentageGraph`;
export const apiGetDashboardInformationQueue = `${resolveUrl()}/api/DashboardGeneralStatusQueue/GetDashboardInformationQueue`;
export const apiGetDashboardInformationQueueFilterByAttentionLine = `${resolveUrl()}/api/DashboardGeneralStatusQueue/GetDashboardInformationQueueFilterByAttentionLine`;
export const apiGetDashboardInformationQueueFilterByCampings = `${resolveUrl()}/api/DashboardGeneralStatusQueue/GetDashboardInformationQueueFilterByCampings`;
export const apiGetDashboardInformationServiceAgents = `${resolveUrl()}/api/Dashboard/GetDashboardInformationServiceAgents`;
export const apiGetServiceAgentData = `${resolveUrl()}/api/Dashboard/GetServiceAgentData`;
export const apiGetDashboardInformationAgentServiceFilterByAttentionLine = `${resolveUrl()}/api/Dashboard/GetDashboardInformationAgentServiceFilterByAttentionLine`;
export const apiGetDashboardInformationAgentServiceFilterByCampings = `${resolveUrl()}/api/Dashboard/GetDashboardInformationAgentServiceFilterByCampings`;

export const apiPositionhub = `${resolveUrl()}/positionhub`;
export const apiDownloadDetailConversation = `${resolveUrl()}/api/Dashboard/DownloadDetailConversation`;
export const apiUpdUserState = `${resolveUrl()}/api/Users/UpdUserState`;
export const apiGetActiveConversationsByAgent = `${resolveUrl()}/api/Dashboard/GetActiveConversationsByAgent`;
export const apiGetLivePersonConversations = `${resolveUrl()}/api/LivePersonChat/GetLivePersonConversations`;
export const apiDownloadDetailConversationLp = `${resolveUrl()}/api/LivePersonChat/DownloadDetailConversation`;
export const apiGetLivePersonChat = `${resolveUrl()}/api/LivePersonChat/GetLivePersonChat`;

export const apiGetBarGraphPollAnswers = `${resolveUrl()}/api/Settings/GetBarGraphPollAnswers`;
export const apiGetCircleGraphPollAnswers = `${resolveUrl()}/api/Settings/GetCircleGraphPollAnswers`;
export const apiGetPollAnswers = `${resolveUrl()}/api/Settings/GetPollAnswers`;
export const apiGetPollAnswersByQuestion = `${resolveUrl()}/api/Settings/GetPollAnswersByQuestion`;

export const apiGetUrlReporte = `${resolveUrl()}/api/ReportApi/GetUrlReporte`;
export const apiGetUsersMultigestor = `${resolveUrl()}/api/ReportApi/GetUsersMultigestor`;
export const apiGetVicidialUsers = `${resolveUrl()}/api/ReportApi/GetVicidialUsers`;
export const apiGetAgentesConsultas = `${resolveUrl()}/api/ReportApi/GetAgentesConsultas`;
export const apiGetAgentSummaryReport = `${resolveUrl()}/api/ReportApi/GetAgentSummaryReport`;
export const apiReportConversationDetails = `${resolveUrl()}/api/ReportApi/GetConversationDetailsReportData`;
export const apiReportCallDetail = `${resolveUrl()}/api/ReportApi/GetCallDetailReportData`;
export const apiReportCallDetailAutomaticDialing = `${resolveUrl()}/api/ReportApi/GetCallDetailAutomaticDialingReportData`;
export const apiReportEmailAttention = `${resolveUrl()}/api/ReportApi/GetEmailAttentionReportData`;
export const apiReportProductivityAgent = `${resolveUrl()}/api/ReportApi/GetProductivityAgentReportData`;
export const apiReportDetailBreaksAgent = `${resolveUrl()}/api/ReportApi/GetDetailBreaksAgentReportData`;
export const apiReportDetailNumberCallsTimeRanges = `${resolveUrl()}/api/ReportApi/GetDetailNumberCallsTimeRangesReportData`;
export const apiReportEfficiency = `${resolveUrl()}/api/ReportApi/GetEfficiencySummaryReportData`;
export const apiReportDialing = `${resolveUrl()}/api/ReportApi/GetDialingSummaryReportData`;
export const apiReportContact = `${resolveUrl()}/api/ReportApi/GetContactSummaryReportData`;

export const apiGetQnA = `${resolveUrl()}/api/QnA/GetQnA`;
export const apiSaveQnA = `${resolveUrl()}/api/QnA/SaveQnA`;
export const apiSaveAndPublisQnA = `${resolveUrl()}/api/QnA/SaveAndPublisQnA`;

export const apiGetPlatformSetengs = `${resolveUrl()}/api/PlatformSetings/GetPlatformSetengs`;
export const apiSavePlatformSetengs = `${resolveUrl()}/api/PlatformSetings/SavePlatformSetengs`;
export const apiGetViciChatGroups = `${resolveUrl()}/api/ViciGroups/GetViciChatGroups`;


export const apiGetEmoticons = `${resolveUrl()}/api/PlatformSetings/GetEmoticons`;
export const apiPostEmoticons = `${resolveUrl()}/api/PlatformSetings/PostEmoticons`;
export const apiDeleteEmoticons = `${resolveUrl()}/api/PlatformSetings/DeleteEmoticons`;
export const apiAddEmoticons = `${resolveUrl()}/api/PlatformSetings/AddEmoticons`;
export const apiEditEmoticons = `${resolveUrl()}/api/PlatformSetings/EditEmoticons`;


export const apiGetChannelsConfig = `${resolveUrl()}/api/ChannelsConfig/GetChannelsConfig`;
export const apiPutChannelsConfig = `${resolveUrl()}/api/ChannelsConfig/PutChannelsConfig`;
export const apiEmails = `${resolveUrl()}/api/Emails/SendEmail`;
export const apiGetData = `${resolveUrl()}/api/Emails/GetData`;
export const apiGetEmailsData = `${resolveUrl()}/api/Emails/GetEmailsData`;
export const apiGetJsonMenu = `${resolveUrl()}/api/Users/GetJsonMenu`;
//#endregion IVR

//#region Campaings
export const  apiGetCampaignsList = `${resolveUrl()}/api/Campaigns/GetCampaignsList`;
export const  apiGetCampaign = `${resolveUrl()}/api/Campaigns/GetCampaign`;
export const  apiCreateCampaign = `${resolveUrl()}/api/Campaigns/CreateCampaign`;
export const  apiUpdateCampaign = `${resolveUrl()}/api/Campaigns/UpdateCampaign`;
export const  apiGetCampaignFromVici = `${resolveUrl()}/api/Campaigns/GetCampaignFromVici`;
export const  apiGetDefaultCampaigns = `${resolveUrl()}/api/Campaigns/GetDefaultCampaigns`;
export const  apiCheckExistCampaing = `${resolveUrl()}/api/Campaigns/CheckExistCampaing`;
export const  apiSetTransfer = `${resolveUrl()}/api/Campaigns/SetTransfer`;

export const  apiGetAgent = `${resolveUrl()}/api/Campaigns/GetAgents`;
export const  apiAddUserCampaign = `${resolveUrl()}/api/Campaigns/AddUserCampaign`;
//#endregion Campaings

//#region USERS
export const apiGetAllUsers = `${resolveUrl()}/api/Users/GetAllUsers`;
export const apiGetUserById = `${resolveUrl()}/api/Users/GetUserById`;
export const apiCreateUser = `${resolveUrl()}/api/Users/CreateUser`;
export const apiDeleteUsers = `${resolveUrl()}/api/Users/DeleteUsers`; 
export const apiGetUserCampaigns = `${resolveUrl()}/api/Users/GetUserCampaigns`; 
export const apiGetAttentionLineXUser = `${resolveUrl()}/api/Users/GetAttentionLineXUser`;
export const apiGetCampaignsByUser = `${resolveUrl()}/api/Users/GetCampaignsByUser`;
export const apiGetEmailSignature = `${resolveUrl()}/api/Users/GetEmailSignature`;
export const apiGetActiveAgentsList = `${resolveUrl()}/api/Agent/GetActiveAgentsList`;
//#endregion USERS

//#region SipPhone
export const apiGetSessionName = `${resolveViciUrl()}/api/AsteriskLogin/GetSessionName`;
export const apiGetExtraCredencials = `${resolveUrl()}/api/Users/GetExtraCredencials`;
export const apiUpdatePhone = `${resolveViciUrl()}/api/AsteriskLogin/UpdatePhone`;
export const apiChangeCampaing = `${resolveUrl()}/api/Users/ChangeCampaing`;
export const apiVicidialLogOut = `${resolveUrl()}/api/Users/VicidialLogOut`;
//#endregion SipPhone
export const apiGetLastChatOrEmail = `${resolveViciUrl()}/api/AttentionLine/GetLastChatOrEmail`;


//#region CLIENTS
export const apiGetAllClients = `${resolveUrl()}/api/Clients/GetAllClients`;
export const apiGetClientById = `${resolveUrl()}/api/Clients/GetClientById`;
export const apiDeleteClients = `${resolveUrl()}/api/Clients/DeleteClient`; 
export const apiGetPauseDetailCatalog = `${resolveUrl()}/api/Clients/GetPauseDetailCatalog`;
//#endregion CLIENTS

//#region ROLES
export const apiGetAllRoles = `${resolveUrl()}/api/Roles/GetAllRoles`;
export const apiGetRoleCodeList = `${resolveUrl()}/api/Roles/GetRoleCodeList`;
export const apiGetRoleById = `${resolveUrl()}/api/Roles/GetRoleById`;
export const apiDeleteRole = `${resolveUrl()}/api/Roles/DeleteRole`; 
export const apiAddRole    = `${resolveUrl()}/api/Roles/AddRole`;
export const apiUpdateRole = `${resolveUrl()}/api/Roles/UpdateRole`;

export const apiGetMenu = `${resolveUrl()}/api/Roles/GetMenu`;
export const apiAddMenuRole = `${resolveUrl()}/api/Roles/AddMenuRole`;
//#endregion ROLES

//#region LINEAS DE ATENCION
export const apiGetVicidialUserGroups = `${resolveUrl()}/api/AttentionLine/GetVicidialUserGroups`;
export const apiGetAttentionLine = `${resolveUrl()}/api/AttentionLine/GetAttentionLine`;
export const apiGetAttentionLineList = `${resolveUrl()}/api/AttentionLine/GetAttentionLineList`;
export const apiDeleteAttentionLine = `${resolveUrl()}/api/AttentionLine/DeleteAttentionLine`;
export const apiGetFullAttentionLine = `${resolveUrl()}/api/AttentionLine/GetFullAttentionLine`;
export const apiUpdateAttentionLine = `${resolveUrl()}/api/AttentionLine/UpdateAttentionLine`;
export const apiInsertAttentionLine = `${resolveUrl()}/api/AttentionLine/InsertAttentionLine`;
export const apiCopytAttentionLine = `${resolveUrl()}/api/AttentionLine/InsertCopytAttentionLine`;
export const apiGetAttentionLineByChannel = `${resolveUrl()}/api/AttentionLine/GetAttentionLineByChannel`;
export const apiGetconciergeBotUrlByVideoChatAttentionLineCode = `${resolveUrl()}/api/AttentionLine/GetconciergeBotUrlByVideoChatAttentionLineCode`;
export const apiGetTransferAttentionLinebyCampaign = `${resolveUrl()}/api/AttentionLine/GetTransferAttentionLinebyCampaign`;

export const apiGetUnidadNegocio = `${resolveUrl()}/api/AttentionLine/GetUnidadNegocio`;
export const apiGetBusinessUnitByclients = `${resolveUrl()}/api/AttentionLine/GetBusinessUnitByclients`;
export const apiGetOriginType = `${resolveUrl()}/api/AttentionLine/GetOriginType`;
export const apiGetOriginLine = `${resolveUrl()}/api/AttentionLine/GetOriginLine`;
export const apiGetService = `${resolveUrl()}/api/AttentionLine/GetService`
export const apiGetDataCaso = `${resolveUrl()}/api/AttentionLine/GetDataCaso`
export const apiGetQuestionType = `${resolveUrl()}/api/AttentionLine/GetQuestionType`
export const apiGetSubMenuTiposConsultas = `${resolveUrl()}/api/AttentionLine/GetSubMenuTiposConsultas`
export const apiGetListaTiposConsultas = `${resolveUrl()}/api/AttentionLine/GetTiposConsultas`

export const apiGetCanalsList = `${resolveUrl()}/api/AttentionLine/GetCanalsList`
export const apiGetAllCanalsList = `${resolveUrl()}/api/AttentionLine/GetAllCanalsList`

export const apiGetDefaultAttentionLine = `${resolveUrl()}/api/AttentionLine/GetDefaultAttentionLine`
//#endregion LINEAS DE ATENCION

//#region Chats/Correos
export const apiGetEmails = `${resolveUrl()}/api/Chat/GetEmails`;
export const apiSendEmails = `${resolveUrl()}/api/Chat/SendEmail`;
export const apiHangUpChat = `${resolveUrl()}/api/HangUp/HangUp`;
export const apiCloseCurrentConversation = `${resolveUrl()}/api/Chat/CloseCurrentConversation`;
export const apiGetDatabyUltIDCaso = `${resolveUrl()}/api/Chat/GetDatabyUltIDCaso`;
export const apiTransferChat = `${resolveUrl()}/api/Chat/TransferChat`;
export const apiUpdateStatusInCallVideoChat = `${resolveUrl()}/api/Chat/UpdateStatusInCallVideoChat`;
//#endregion Chats/Correos

//#region General
export const apiConvertTextToSpeech = `${resolveUrl()}/api/General/ConvertTextToSpeech`;
export const apiGetReselerInfo = `${resolveUrl()}/api/General/GetReselerInfo`;


export const apiGetProvincia = `${resolveUrl()}/api/General/GetProvincia`;
export const apiGetCanton = `${resolveUrl()}/api/General/GetCanton`;
export const apiGetDistrito = `${resolveUrl()}/api/General/GetDistrito`;
export const apiGetIdentificationType = `${resolveUrl()}/api/General/GetIdentificationType`;
export const apiGetNameByIdentification = `${resolveUrl()}/api/General/GetNameByIdentification`;
export const apiGetDataByCall = `${resolveUrl()}/api/General/GetDataByCall`;
export const apiGetKeySettingsCatalog = `${resolveUrl()}/api/General/GetKeySettingsCatalog`;
export const apiGetPhoneNumbersList = `${resolveUrl()}/api/General/GetPhoneNumbersList`;
export const apiSubmitClient = `${resolveUrl()}/api/Clients/SubmitClient`;
export const apiSubmitResellers = `${resolveUrl()}/api/Clients/SubmitResellers`;

export const apiGetMainColor = `${resolveUrl()}/api/General/GetMainColor`;
export const apiGetLogoUrl = `${resolveUrl()}/api/General/GetLogoUrl`;
export const apiGetLoginImagesUrls = `${resolveUrl()}/api/General/GetLoginImagesUrls`;
export const apiGetSecondaryColor = `${resolveUrl()}/api/General/GetSecondaryColor`;
//#endregion General

//#region CRM INSTITUCION
export const apiGetIdentificationTypebyInstitution = `${resolveUrl()}/api/General/GetIdentificationTypebyInstitution`;
//#endregion CRM INSTITUCION

//#region  Chats / Chats
//export const apiGetAgentChat = `${resolveUrl()}/api/AgentDashboard/GetAgentChat`;
export const apiPostSaveCaso = `${resolveUrl()}/api/Chat/SaveCaso`;
export const apiGetNumChatsAsing = `${resolveUrl()}/api/Chat/GetNumChatsAsing`;
export const apiGetAgentChat = `${resolveUrl()}/api/Agent/GetAgentChat`;
export const apiValidateAsignetChats = `${resolveUrl()}/api/Agent/ValidateAsignetChats`;
export const apiGetChatByCaseId = `${resolveUrl()}/api/Agent/GetChatBYCaseId`;
export const apiSaveVideoChatRecorder = `${resolveUrl()}/api/Chat/SaveVideoChatRecorder`;
export const apiGetBanderaResponseByAgentEmail = `${resolveUrl()}/api/Chat/GetBanderaResponseByAgentEmail`;
//#endregion  Chats / Chats

//#region Casa del Tanque
export const apiGetContactoCasaTanque = `${resolveUrl()}/api/Chat/GetContactoCasaTanque`;
//#endregion Casa del Tanque

//#region CRM
export const apiModalCreateCasoMP = `${resolveUrl()}/api/CRM/ModalCreateCasoMP`; // Modal crear caso MP
export const apiGetListTypeIdByClient = `${resolveUrl()}/api/CRM/GetListTypeIdByClient`; // Modal crear caso MP

export const apiGetCRMList = `${resolveUrl()}/api/CRM/GetCRMList`;
export const apiGetAgentList = `${resolveUrl()}/api/CRM/GetAgentList`;
export const apiGetSearchCases = `${resolveUrl()}/api/CRM/GetSearchCases`;
export const apiGetListTypeId = `${resolveUrl()}/api/CRM/GetListTypeId`;
export const apiGetCase = `${resolveUrl()}/api/CRM/GetCase`;
export const apiGetListProvince = `${resolveUrl()}/api/CRM/GetListProvince`;
export const apiGetListCanton = `${resolveUrl()}/api/CRM/GetListCanton`;
export const apiGetListDistrict = `${resolveUrl()}/api/CRM/GetListDistrict`;
export const apiGetListSourceType = `${resolveUrl()}/api/CRM/GetListSourceType`;
export const apiGetListOriginLine = `${resolveUrl()}/api/CRM/GetListOriginLine`;
export const apiGetListServices = `${resolveUrl()}/api/CRM/GetListServices`;
export const apiGetListCondition = `${resolveUrl()}/api/CRM/GetListCondition`;
export const apiGetActivityByCase = `${resolveUrl()}/api/CRM/GetActivityByCase`;
export const apiGetTranscriptData = `${resolveUrl()}/api/CRM/getTranscriptData`;
export const apiUpdateCase = `${resolveUrl()}/api/CRM/UpdateCase`;
export const apiGetListNotes = `${resolveUrl()}/api/CRM/GetListNotes`;
export const apiGetListSubNotes = `${resolveUrl()}/api/CRM/GetListSubNotes`;
export const apiSubmitNote = `${resolveUrl()}/api/CRM/SubmitNote`;
export const apiGetListNotesByCase = `${resolveUrl()}/api/CRM/GetListNotesByCase`;
export const apiDeactivateNote = `${resolveUrl()}/api/CRM/DeactivateNote`;
export const apiGetListGender = `${resolveUrl()}/api/CRM/GetListGender`;
export const apiGetChat = `${resolveUrl()}/api/Attention/GetChat`;
export const apiGetPredefinedMessagesListByAttentionLineType = `${resolveUrl()}/api/PredefinedMessages/GetListByAttentionLineType`;
export const apiGetPredefinedMessagesList = `${resolveUrl()}/api/PredefinedMessages/GetList`;
export const apiGetPredefinedMessages = `${resolveUrl()}/api/PredefinedMessages/GetPredefinedMessages`;
export const apiSubmitPredefinedMessages = `${resolveUrl()}/api/PredefinedMessages/SubmitPredefinedMessages`;
export const apiDeltePredefinedMessages = `${resolveUrl()}/api/PredefinedMessages/DeltePredefinedMessages`;
export const apiObtenerAudioCase = `${resolveUrl()}/api/CRM/ObtenerAudioCase`;
export const apiGetListOriginTypeForFilter = `${resolveUrl()}/api/CRM/GetListOriginTypeForFilter`;
export const apiGetVideoChat = `${resolveUrl()}/api/CRM/GetVideoChat`;
export const apiGetBanderaApiSentimientos = `${resolveUrl()}/api/CRM/GetBanderaApiSentimientos`;
//#endregion CRM

//#region CRM Api's
export const apiGetTiposLineaAtencionInstitucion = `${resolveUrl()}/api/CRM/GetTiposLineaAtencionInstitucion`;
export const apiGetTiposIdentificacion = `${resolveUrl()}/api/CRM/GetTiposIdentificacionApiMultigestor`;
export const apiGetProvinciasApiMultigestor = `${resolveUrl()}/api/CRM/GetProvinciasApiMultigestor`;
export const apiGetCantonesApiMultigestor = `${resolveUrl()}/api/CRM/GetCantonesApiMultigestor`;
//#endregion CRM Api's

//#region Telefonía
export const apiEndCall = `${resolveUrl()}/api/Calls/EndCall`;
export const apiSendPhonelistVici = `${resolveUrl()}/api/Calls/sendPhonelistVici`;
export const apiPostPrincipalList = `${resolveUrl()}/api/Calls/PostPrincipalList`;
export const apiGetAllListPrincipal = `${resolveUrl()}/api/Calls/GetAllListPrincipal`;
export const apiPostPhoneListExcel = `${resolveUrl()}/api/Calls/PostPhoneListExcel`;
export const apiUpdatePrincipalList = `${resolveUrl()}/api/Calls/UpdatePrincipalList`;
export const apiGetPhoneListExcelById = `${resolveUrl()}/api/Calls/GetPhoneListExcelById`;
export const apiGetAllPhoneListExcel = `${resolveUrl()}/api/Calls/GetAllPhoneListExcel`;
//#endregion Telefonía

//#region dashboard
export const apiGetTiempoAcomuladoPausas = `${resolveUrl()}/api/Dashboard/GetTiempoAcomuladoPausas`;
export const apiSubmitDashboardSettings = `${resolveUrl()}/api/DashboardSettings/SubmitDashboardSettings`; 
export const apiSubmitGeneralDashboardChartConfig = `${resolveUrl()}/api/DashboardSettings/SubmitGeneralDashboardChartConfig`; 
export const apiGetDashboardSettingsByClient = `${resolveUrl()}/api/DashboardSettings/GetDashboardSettingsByClient`; 
export const apiSubmitStaffComplianceChart = `${resolveUrl()}/api/DashboardSettings/SubmitStaffComplianceChart`;
export const apiGetDashboardInformationFiltered = `${resolveUrl()}/api/Dashboard/GetDashboardInformationFiltered`;
export const apiGetDashboardInformationLineChartFiltered = `${resolveUrl()}/api/Dashboard/GetDashboardInformationLineChartFiltered`;
export const apiGetDashboardInformationLineChartFilteredJob = `${resolveUrl()}/api/Dashboard/GetDashboardInformationLineChartFilteredJob`;
export const apiGetDashboardInformationLineChart = `${resolveUrl()}/api/Dashboard/GetDashboardInformationLineChart`;
export const apiSubmitDashboardSettingsServiceAgents = `${resolveUrl()}/api/DashboardSettings/SubmitDashboardSettingsServiceAgents`; 
export const apiGetDashboardSettingsServiceAgentsByClient = `${resolveUrl()}/api/DashboardSettings/GetDashboardSettingsServiceAgentsByClient`; 
export const apiGetAllAgentsConnectByClient = `${resolveUrl()}/api/DashboardSettings/GetAllAgentsConnectByClient`; 
export const apiGetDashboardChartSettings = `${resolveUrl()}/api/DashboardSettings/GetDashboardChartSettings`;
export const apiSubmitGeneralDashboardSettings_VI_TMO_TA = `${resolveUrl()}/api/DashboardSettings/SubmitGeneralDashboardSettings_VI_TMO_TA`; 
export const apiSubmitGeneralDashboardSettings_Queue_Agents = `${resolveUrl()}/api/DashboardSettings/SubmitGeneralDashboardSettings_Queue_Agents`; 
//#endregion dashboard

//#region OutboundCampaign - Template
export const  apiGetOutboundCampaignTemplate = `${resolveUrl()}/api/Campaigns/GetOutboundCampaignTemplate`;
export const  apiGetTypeTemplate = `${resolveUrl()}/api/Campaigns/GetTypeTemplate`;
export const  apiPostTemplate = `${resolveUrl()}/api/Campaigns/PostTemplate`;
export const  apiUpdateTemplate = `${resolveUrl()}/api/Campaigns/UpdateTemplate`;
export const  apiPostParametersTemplate = `${resolveUrl()}/api/Campaigns/PostParametersTemplate`;
export const  apiGetParametersTemplate= `${resolveUrl()}/api/Campaigns/GetParametersTemplate`;
export const  apiPostUpdateParametersTemplate= `${resolveUrl()}/api/Campaigns/UpdateParametersTemplate`;
export const  apiPostDesactivarParametersTemplate= `${resolveUrl()}/api/Campaigns/DesactivarParametersTemplate`;
export const  apiGetAudioTemplate= `${resolveUrl()}/api/Campaigns/GetAudioTemplate`;
//#endregion OutboundCampaign - Template

//#region OutboundCampaign - Campaigns
export const  apiGetOutboundCampaign = `${resolveUrl()}/api/Campaigns/GetOutboundCampaign`;
export const  apiGetOutboundCampaignStatus = `${resolveUrl()}/api/Campaigns/GetOutboundCampaignStatus`;
export const  apiPostOutboundCampaign = `${resolveUrl()}/api/Campaigns/PostOutboundCampaign`;
export const  apiSendTest = `${resolveUrl()}/api/Campaigns/SendTest`;
export const  apiUpdStatus = `${resolveUrl()}/api/Campaigns/UpdStatusCampaign`;
export const  apiPostUpdOutboundCampaign = `${resolveUrl()}/api/Campaigns/UpdateOutboundCampaign`;
export const  apiUpdRenameParameters = `${resolveUrl()}/api/Campaigns/UpdRenameParameters`;
export const  apiInactivateTemplate= `${resolveUrl()}/api/Campaigns/InactivateTemplate`;
export const  apiGetAvailableRemoteAgents= `${resolveUrl()}/api/Campaigns/GetAvailableRemoteAgents`;
export const  apiSetCampaignToRemoteAgent= `${resolveUrl()}/api/Campaigns/SetCampaignToRemoteAgent`;
export const  apisetAvailable= `${resolveUrl()}/api/Campaigns/setAvailable`;
export const  apiGetCampaignBusinessUnitIdByAttentionLine= `${resolveUrl()}/api/Campaigns/GetCampaignBusinessUnitIdByAttentionLine`;
export const  apiGetEmailDomain= `${resolveUrl()}/api/Campaigns/GetEmailDomain`;
//#endregion OutboundCampaign - Campaigns

//#region Security - api Token
export const apiGetApiViciToken = `${resolveUrl()}/api/security/GetApiViciToken`;
//#endregion Security - api Token

//#region SoftphoneNew
export const apiAllHangUpK360 = `${resolveUrl()}/api/Users/VicidialUserAllHangUp`;
export const apiGetServers = `${resolveViciUrl()}/api/asterisklogin/GetAsteriskUser`;
export const apiTransferExtenURL = `${resolveUrl()}/api/AttentionLine/GetCallsSurveyextension`;
export const apiTransferURL = `${resolveUrl()}/api/AttentionLine/GetTransferAttentionLines`;
export const apiUpdatePhoneURL = `${resolveViciUrl()}/api/asterisklogin/UpdatePhone`;
export const apiWhisperDataURL = `${resolveViciUrl()}/api/asterisklogin/GetSessionName`;
export const apiWhisperUrl = `${viciWebServerOnly}/vicidial/non_agent_api.php`;
export const apiGetCasoByCallerIdURL = `${resolveUrl()}/api/Calls/GetCasoByCallerId?callerid=`;
export const apiSaveCallDataURL = `${resolveUrl()}/api/calls/SaveCallData`;
export const apiSetOutgoingManualCall = `${viciWebServerOnly}/agc/api.php`;
export const apiUseSoftphoneK360 = `${resolveUrl()}/api/Clients/UseSoftphoneK360`;
export const apiUserWhisperByServer = `${resolveViciUrl()}/api/asterisklogin/GetUserWhisperByServer`;

export const GetApiViciToken = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

    var requestOptions = {
        method: 'Get',
        headers: myHeaders
    };

    return new Promise(function (resolve, reject) {
        fetch(apiGetApiViciToken, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === 200) {
                    resolve(result.data);
                } else {
                    resolve(result.errorMessage);
                }
            })
            .catch(error => {
                console.error('constantasApi -> GetApiViciToken: ', error);
                reject("Error, " + error);
            });
    });
};
export const NotifyEvent = (pMessage, pIcon) => {
	Swal.fire({
		position: "top-end",
		icon: pIcon,
		text: pMessage,
		showConfirmButton: false,
		timer: 10000
	});
};
export const NotifyOkEvent = (pMessage, pIcon) => {
	Swal.fire({
		icon: pIcon,
		text: pMessage,
	});
};
export const UseSoftphoneK360 = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

    var requestOptions = {
        method: 'Get',
        headers: myHeaders,
        redirect: "follow"
    };

    let clientId = localStorage.getItem('clientId');   
    return new Promise(function (resolve, reject) {
        fetch(apiUseSoftphoneK360 + "?clientId=" + clientId, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.statusCode === 200) {
                    resolve(result.data);
                } else {
                    resolve(result.errorMessage);
                }
            })
            .catch(error => {
                console.error('constantasApi -> GetApiViciToken: ', error);
                reject("Error, " + error);
            });
    });
};
//#endregion SoftphoneNew
export const RefreshToken = () => {

    return new Promise(function (resolve, reject) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

        var requestOptions = {
            method: 'Get',
            headers: myHeaders
        };

        fetch(apiRefreshToken + "?userId=" + parseInt(localStorage.getItem("userId")), requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.data) {
                    //localStorage.removeItem('accessToken');
                    localStorage.setItem('accessToken', result.data);
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(error => {
                //alert("Hemos tenido un inconveniente, Intente de nuevo en un momento.");
                console.error(error);
                resolve(false);
            });
    });
};
//Reportes
export const apiReportGeneralSummary = `${resolveUrl()}/api/Report/GetReportGeneralSummary`;
export const apiReportTMO = `${resolveUrl()}/api/Report/GetTMOReport`;
export const apiGetTrafficReport = `${resolveUrl()}/api/Report/GetTrafficReport`;


export const apiGetPauseDetailCatalogList = `${resolveUrl()}/api/Pauses/GetPauseDetailCatalogList`;
export const apiGetPauseDetailCatalogPause = `${resolveUrl()}/api/Pauses/GetPauseDetailCatalog`;
export const apiInsertPauseDetailCatalog = `${resolveUrl()}/api/Pauses/InsertPauseDetailCatalog`;
export const apiEditPauseDetailCatalog = `${resolveUrl()}/api/Pauses/EditPauseDetailCatalog`;
export const apiDeletePauseDetailCatalog = `${resolveUrl()}/api/Pauses/DeletePauseDetailCatalog`;

export const apiUpdateAttentionLineQueuePriority = `${resolveUrl()}/api/AttentionLine/UpdateAttentionLineQueuePriority`;

