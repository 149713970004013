
import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const Qna = Loadable(lazy(() => import("./Qna")));

const QnaRoutes = [
    {
        path: '/qna/qna',
        element: <Qna />,
    }
];

export default QnaRoutes;