import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const ClientList = Loadable(lazy(() => import("./ClientList")))
const ClientSubmit = Loadable(lazy(() => import("./Clients/ClientsSubmit")));

const ClientesRoutes = [
  { 
    path: "/clients/ClientList",
    element: <ClientList />
  },
  { 
    path: "/clients/Update",
    element: <ClientSubmit />
  },
  { 
    path: "/clients/Add",
    element: <ClientSubmit />
  }
];

export default ClientesRoutes;