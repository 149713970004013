import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Role = Loadable(lazy(() => import("./RoleMain")))
const EditRole = Loadable(lazy(() => import("./Role/Role")));

const RoleRoutes = [
  { 
    path: "/role/roleMain",
    element: <Role />
  },
  { 
    path: "/role/edit",
    element: <EditRole />
  },
  { 
    path: "/role/add",
    element: <EditRole />
  }
];

export default RoleRoutes;