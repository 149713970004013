export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const SET_INIT_STATE = "SET_INIT_STATE";
export const SET_AGENT_CHAT = "SET_AGENT_CHAT";
export const SET_LOADING = "SET_LOADING";
export const SET_SHOW_ERROR_HUB = "SET_SHOW_ERROR_HUB";
export const SET_ACTIVE_EMAIL = "SET_ACTIVE_EMAIL";
export const SET_ALL_EMAIL = "SET_ALL_EMAIL";
export const SET_CHATS_GROUPID = "SET_CHATS_GROUPID";
export const SET_EMAILS_GROUPID = "SET_EMAILS_GROUPID";
export const SET_PHONES_GROUPID = "SET_PHONES_GROUPID";
export const SET_DISABLEHANGUP = "SET_DISABLEHANGUP";
export const SET_DISABLE_CHANGESTATUS = "SET_DISABLE_CHANGESTATUS";
export const SET_NOTIFICATION_TAB = "SET_NOTIFICATION_TAB";

