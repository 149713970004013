import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const PlatformSettings = Loadable(lazy(() => import("./PlatformSettings")));

const PlatformSettingsRoutes = [
    {
        path: '/platformSettings/platformSettings',
        element: <PlatformSettings />,
    }
];

export default PlatformSettingsRoutes;