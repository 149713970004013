import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PauseDetailCatalogList = Loadable(lazy(() => import("./PauseDetailCatalogList")));
const SubmitPauseDetailCatalog = Loadable(lazy(() => import("./SubmitPauseDetailCatalog")));

const PauseDetailCatalogRoutes = [
    {
        path: '/pausedetail/pausedetailCatalog',
        element: <PauseDetailCatalogList />
    },{
        path: '/pausedetail/submitPauseDetailCatalog',
        element: <SubmitPauseDetailCatalog />
    },
]

export default PauseDetailCatalogRoutes;

