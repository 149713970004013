import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Polls = Loadable(lazy(() => import("./Polls")));
const EditPoll = Loadable(lazy(() => import("./polls/EditPoll")));
const PollAnswers = Loadable(lazy(() => import("./polls/PollAnswers")));
const PollAnswersByQuestion = Loadable(lazy(() => import("./polls/PollAnswersByQuestion")));
const Troncals = Loadable(lazy(() => import("./Troncals")));
const Ivrs = Loadable(lazy(() => import("./Ivrs")));
const IvrMain = Loadable(lazy(() => import("./IvrMain")));
const SubmitIvr = Loadable(lazy(() => import("./ivr/SubmitIvr")));

const settingsRoutes = [
    {
        path: '/settings/polls',
        element: <Polls />
    },
    {
        path: '/settings/editpoll',
        element: <EditPoll />
    },
    {
        path: '/settings/pollanswers',
        element: <PollAnswers />
    },
    {
        path: '/settings/pollanswersbyquestion',
        element: <PollAnswersByQuestion />
    },
    {
        path: '/settings/troncals',
        element: <Troncals />
    },
    {
        path: '/settings/ivrMain',
        element: <IvrMain />
    },
    {
        path: '/settings/ivrs',
        element: <Ivrs />
    },
    {
        path: '/settings/submitivr',
        element: <SubmitIvr />
    },
]

export default settingsRoutes
