import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Channels = Loadable(lazy(() => import("./ChannelsAdmin")));

const ChannelsAdminRoutes = [
    {
        path: '/ChannelsAdmin/ChannelsAdmin',
        element: <Channels />
    },
]

export default ChannelsAdminRoutes;