import React from 'react'
import useAuth from 'app/hooks/useAuth'
import { Navigate, useNavigate } from 'react-router-dom'
import { RefreshToken, apiRefreshToken, apiDisconnectUser } from 'app/utils/constantsAPI'

const DisconnectUser = () => {

    return new Promise(function (resolve, reject) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

        var requestOptions = {
            method: 'Get',
            headers: myHeaders
        };

        fetch(apiDisconnectUser + "?userId=" + parseInt(localStorage.getItem("userId")), requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userAvatar');
                localStorage.removeItem('userName');
                localStorage.removeItem('clientName');
                localStorage.removeItem('sessionTime');
                resolve(true);
            })
            .catch(error => {
                //alert("Hemos tenido un inconveniente, Intente de nuevo en un momento.");
                resolve(false);
            });
    });
};

/*const RefreshToken = () => {

    return new Promise(function (resolve, reject) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

        var requestOptions = {
            method: 'Get',
            headers: myHeaders
        };

        fetch(apiRefreshToken + "?userId=" + parseInt(localStorage.getItem("userId")), requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.removeItem('accessToken');
                localStorage.setItem('accessToken', result.data);
                resolve(true);
            })
            .catch(error => {
                //alert("Hemos tenido un inconveniente, Intente de nuevo en un momento.");
                resolve(false);
            });
    });
};*/


/*const parseJwt = (token, navigate) => {

    try {
        let b = JSON.parse(atob(token.split(".")[1]));
       
        //Si ingresa al if es que expiro
        if ((b.exp * 1000) < Date.now()) {

            DisconnectUser();

            navigate("/session/signin", { state: {isUserDisconnected: true }});

            // alert('Su sesión ya expiro, se debe de volver a loguear.')

            return false;

        }
        //refresh
        else {

            RefreshToken();
            return true;
        }

    } catch (e) {
        return null;
    }
};


const AuthGuard = ({ children }) => {
    const navigate = useNavigate();

    console.log('Out')
    const { isAuthenticated } = useAuth()

    let decodedJwt = parseJwt(localStorage.getItem("accessToken"), navigate);

    if (!decodedJwt) {

        return <>{<Navigate to="/session/signin"/>}</>
    }

    return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>
}*/

const AuthGuard = ({ children }) => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    // console.log('AuthGuard');
    let token = localStorage.getItem("accessToken");
    if (token) {
        let b = JSON.parse(atob(token.split(".")[1]));
        let dateNow = Date.now();

        if ((b.exp * 1000) < dateNow) {//Si ingresa al if es que expiro                
            DisconnectUser().then((result) => {
                console.log('AuthGuard - DisconnectUser', result);
                navigate("/session/signin", { state: {isUserDisconnected: true }});
                return <>{<Navigate to="/session/signin" />}</>
            });
        } else {
            RefreshToken().then((result) => {
                console.log('AuthGuard - RefreshToken', result);
            });
        }
    }
    return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>
};
export default AuthGuard;