
import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

const CRMList = Loadable(lazy(() => import("./CRMList")));
//const EditUser = Loadable(lazy(() => import("./User/EditUser")));

const CRMRoutes = [
    {
        path: '/crm/crmlist',
        element: <CRMList />,
    }
    /*,
    {
        path: '/users/edit',
        element: <EditUser />
    }*/
];

export default CRMRoutes;