import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PredefinedMessagesList = Loadable(lazy(() => import("./PredefinedMessagesList")));
const PredefinedMessagesSubmit = Loadable(lazy(() => import("./PredefinedMessagesSubmit")));

const PredefinedMessagesRoutes = [
    {
        path: '/predefinedMessages/PredefinedMessagesList',
        element: <PredefinedMessagesList />
    },
    {
        path: '/predefinedMessages/PredefinedMessagesSubmit',
        element: <PredefinedMessagesSubmit />
    },
]

export default PredefinedMessagesRoutes;

