import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Reports = Loadable(lazy(() => import("./Reports")));
const NewReport = Loadable(lazy(() => import("./NewReport")));

const ReportsRoutes = [
    {
        path: '/reports/reports',
        element: <Reports />
    },
    {
        path: '/reports/newreport',
        element: <NewReport /> 
    },
]

export default ReportsRoutes;

