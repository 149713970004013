import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const WindowChat = Loadable(lazy(() => import("./WindowChat")));

const windowChatRoutes = [
    {
        path: '/WindowChat/windowChat/',
        element: <WindowChat />
    },
]

export default windowChatRoutes
