import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const NotFound = Loadable(lazy(() => import("./NotFound")));
const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("./register/JwtRegister")));
const JwtExternLogin = Loadable(lazy(() => import("./login/ViciLogin")));
const ResetPassword = Loadable(lazy(() => import("./ResetPassword")));
const Unsubscribe = Loadable(lazy(() => import("./Unsubscribe")));
const VideoChatContainer = Loadable(lazy(() => import("./VideoChatContainer")));

const sessionRoutes = [
    {
        path: '/session/signup',
        element: <JwtRegister />,
    },
    {
        path: '/session/signin',
        element: <JwtLogin />,
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/session/resetPassword/:token',
        element: <ResetPassword />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
    {
        path: '/session/vicidial/:id',
        element: <JwtExternLogin />,
    },
    {
        path: '/session/vicidial/:id/:callback',
        element: <JwtExternLogin />,
    },
    {
        path: '/session/Unsubscribe/:email',
        element: <Unsubscribe />,
    },
    {
        path: '/VideoChatContainer/:videoChatId',
        element: <VideoChatContainer />,
    }
]

export default sessionRoutes
