
import React, { lazy } from "react";
import Loadable from "app/components/Loadable/Loadable";

// const Phone = Loadable(lazy(() => import("./SoftPhone")));
// const SelectCampaign = Loadable(lazy(() => import("./SelectCampaign.jsx")));
const DadCampaignAndPhone = Loadable(lazy(() => import("./DadCampaignAndPhone.jsx")));

const SoftPhoneNewRoutes = [
    {
        path:'/SoftPhoneNew/Phone',
        element: <DadCampaignAndPhone/>
        // element: <SelectCampaign/>
        // element: <Phone type='outbound_calls'/>
        //element: <Phone type='incoming_calls'/>
        // element: <Phone type='supervisor'/>
        // element: <Phone type='OnlyCampaign'/>
    }
];

export default SoftPhoneNewRoutes;