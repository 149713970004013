import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const ResellerSubmit = Loadable(lazy(() => import("./ResellerSubmit")))

const ResellerRoutes = [
  { 
    path: "/reseller/resellerSubmit",
    element: <ResellerSubmit />
  }
];

export default ResellerRoutes;