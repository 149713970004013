import AuthGuard from 'app/auth/AuthGuard'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import dashboardSettingsRoutes from 'app/views/dashboardSettings/DashboardSettingsRoutes'
import dashboardOldRoutes from 'app/views/dashboard_Old/DashboardRoutes'
import conversationsRoutes from "app/views/conversations/ConversationsRoutes"
import livePersonRoutes from "app/views/livePersonConversations/LivePersonRoutes"
import settingsRoutes from "app/views/settings/SettingsRoutes";
import windowChatRoutes from "app/views/windowchat/WindowChatRoutes";
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import EmailsRoutes from 'app/views/emails/EmailsRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import authProtectedRoutes from './routes-Chat';
import reportsRoutes from 'app/views/reports/ReportsRoutes'
import qnaRoutes from 'app/views/qna/QnaRoutes'
import platformSettingsRoutes from 'app/views/platformSettings/PlatformSettingsRoutes'
import channelsAdminRoutes from 'app/views/channelsAdmin/ChannelsAdminRoutes'
import CampaingsRoutes from 'app/views/campaings/CampaingsRoutes'
import UsersRoutes from 'app/views/users/UsersRoutes'
import ClientesRoutes from 'app/views/clients/ClientsRoutes'
import RoleRoutes from 'app/views/Roles/RoleRoutes'
import AttentionListRoutes from 'app/views/attentionList/AttentionListRoutes'
import CRMRoutes from 'app/views/CRM/CRMRoutes'
import PhoneListRoutes from 'app/views/list/PhoneListRoutes';
import PredefinedMessagesRoutes from 'app/views/predefinedMessages/PredefinedMessagesRoutes';
import OutboundCampaignRoutes from 'app/views/OutboundCampaign/OutboundCampaignRoutes'
import ResellerRoutes from 'app/views/reseller/ResellerRoutes'
import EchartForReactRoutes from 'app/views/echartsForReact/EchartForReactRoutes'
import GaugeRoutes from 'app/views/gaugeChartsComponents/GaugeRoutes'
import PauseDetailCatalogRoutes from "app/views/pauses/PauseDetailCatalogRoutes";
import SoftPhoneNewRoutes from 'app/views/SoftphoneNew/SoftPhoneNewRoutes.js';

export const AllPages = () => {

    let principal = "dashboard/default";
    if (localStorage.getItem('role') == 'SUPER') {
        principal = 'dashboard/superUser';
    }
    else if (localStorage.getItem('role') == 'AGENT') {
        principal = 'dashboard/DashboardAgente';
    }
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [...dashboardOldRoutes,
            ...dashboardRoutes, ...dashboardSettingsRoutes, ...chartsRoute, ...materialRoutes,
            ...conversationsRoutes, ...settingsRoutes, ...livePersonRoutes,
            ...windowChatRoutes, ...authProtectedRoutes, ...reportsRoutes,
            ...qnaRoutes, ...platformSettingsRoutes, ...channelsAdminRoutes,
            ...EmailsRoutes, ...CampaingsRoutes, ...UsersRoutes, ...ClientesRoutes,
            ...RoleRoutes, ...AttentionListRoutes, ...CRMRoutes, ...PhoneListRoutes, ...PredefinedMessagesRoutes,...PauseDetailCatalogRoutes, ...OutboundCampaignRoutes,
            ...ResellerRoutes, ...EchartForReactRoutes, ...GaugeRoutes
            ,...SoftPhoneNewRoutes
            ],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to={principal} />,
            //element: <Navigate to="dashboard/default" />,
            //element: <Navigate to="conversations/conversations" />,

        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]
    return all_routes
}
