import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';



const ConversationList = Loadable(lazy(() => import("./ConversationList")))
const LivePersonWindowChat = Loadable(lazy(() => import("./LivePersonWindowChat")))


const LivePersonRoutes = [
  { 
    path: "/livePersonConversations/ConversationList",
    element: <ConversationList/>
  },
  {
    path: "/livePersonConversations/LivePersonWindowChat",
    element: <LivePersonWindowChat/>
  }
];

export default LivePersonRoutes;
