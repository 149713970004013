export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const emailFrom = 'info@kontactum360.com'

export const OriginType = {
    DigitalChannels: 2

}
export const OriginLine = {
    WebChat: 7,
    FaceBook: 5,
    Instagram: 18,
    Twitter: 6,
    Telegram: 17,
    FaceBookPost: "",
    WhatsApp: 4
}