import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const GraphicTest = Loadable(lazy(() => import("./GraphicTest")));
const AllGraphics = Loadable(lazy(() => import("./AllGraphics")));

const Simple = Loadable(lazy(() => import("./1_Simple")));
const Api = Loadable(lazy(() => import("./2_Api")));
const Theme = Loadable(lazy(() => import("./3_Theme")));
const Event = Loadable(lazy(() => import("./4_Event")));
const Loading = Loadable(lazy(() => import("./5_Loading")));
const Dynamic = Loadable(lazy(() => import("./6_Dynamic")));
const WebGl = Loadable(lazy(() => import("./7_WebGL")));
const Locale = Loadable(lazy(() => import("./8_Locale")));
const SVG = Loadable(lazy(() => import("./9_SVG")));
const Graph = Loadable(lazy(() => import("./10_Graph")));

const EchartForReactRoutes = [
    {
        path: '/echartsForReact/Simple',
        element: <Simple />
    },
    {
        path: '/echartsForReact/Api',
        element: <Api />
    },
    {
        path: '/echartsForReact/GraphicTest',
        element: <GraphicTest />
    },
    {
        path: '/echartsForReact/Locale',
        element: <Locale />
    },
    {
        path: '/echartsForReact/Theme',
        element: <Theme />
    },
    {
        path: '/echartsForReact/Event',
        element: <Event />
    },
    {
        path: '/echartsForReact/Loading',
        element: <Loading />
    },
    {
        path: '/echartsForReact/Dynamic',
        element: <Dynamic />
    },
    {
        path: '/echartsForReact/WebGl',
        element: <WebGl />
    },
    {
        path: '/echartsForReact/SVG',
        element: <SVG />
    },
    {
        path: '/echartsForReact/Graph',
        element: <Graph />
    },
    {
        path: '/echartsForReact/AllGraphics',
        element: <AllGraphics />
    }
]

export default EchartForReactRoutes
