import Loadable from 'app/components/Loadable/Loadable';
import React, { lazy } from 'react';

const Main = Loadable(lazy(() => import("./AttentionList")))
const Edit = Loadable(lazy(() => import("./AttentionEdit")))
const AttentionLinePriority = Loadable(lazy(() => import("./AttentionLinePriority")))

const AttentionListRoutes = [
    {
        path: '/attentionList/attentionList',
        element: <Main />
    },
    {
        path: '/attentionList/attentionEdit',
        element: <Edit />
    },
    {
        path: '/attentionList/attentionLinePriority',
        element: <AttentionLinePriority />
    },
]

export default AttentionListRoutes;