import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Campaings = Loadable(lazy(() => import("./Campaings.jsx")));
const SubmitCampaings = Loadable(lazy(() => import("./SubmitCampaing")));

const CampaingsRoutes = [
    {
        path: '/campaings/campaings',
        element: <Campaings />
    },
    {
        path: '/campaings/submitCampaings',
        element: <SubmitCampaings />
    },
]

export default CampaingsRoutes;

