import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { apiLogout, apiProfile } from 'app/utils/constantsAPI'


const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}
const setUser = (userId) => {
    if (userId) {
        localStorage.setItem('userId', userId)
    } else {
        localStorage.removeItem('userId')
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    
    const login = async (email, password) => {

        const response = await axios.post('/api/auth/login', {
            email,
            password,
        })
       
        const { accessToken, user } = response.data
        setUser(user.id)
        localStorage.setItem('userStatus', 'ONLINE')
        localStorage.setItem('showAllConversations', false);
        localStorage.setItem('userAvatar', user.avatar);
        localStorage.setItem('userName', user.name);
        localStorage.setItem('role', user.role);
        localStorage.setItem('clientId', user.clientId);
        localStorage.setItem('clientName', user.clientName);
        localStorage.setItem('sessionTime', user.sessionTime);
        
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = async () => {

        /*
        const response = await axios.post('/api/auth/logout')
        
        const { successful } = response.data

        if(successful)
        {
            setSession(null)
            setUser(null)
            dispatch({ type: 'LOGOUT' })
        }
        */
        let userId = parseInt(localStorage.getItem('userId'));
             
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));
  
        let usuario = JSON.stringify({
          "userId": userId
      });
  
        var requestOptions = {
          method: 'Post',
          headers: myHeaders,
          body: usuario
        };
  
        fetch(apiLogout , requestOptions)
          .then(response => response.json())
          .then(result => {  
              setSession(null)
              setUser(null)
              dispatch({ type: 'LOGOUT' })    
              localStorage.removeItem('userAvatar')    
              localStorage.removeItem('userName')  
              localStorage.removeItem('clientName');
          })
          .catch(error => {
            //setShowGeneralError(true)
            //setMessageError("Hemos tenido un inconveniente, Intente de nuevo en un momento.")
            setTimeout(() => {
              //setShowGeneralError(false)
            }, 10000);
          })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken && isValidToken(accessToken)) {
                    //setSession(accessToken)
                    //const response = await axios.get('/api/auth/profile')
                    //const { user } = response.data

                    let userId = parseInt(localStorage.getItem('userId'));
             
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

                    var requestOptions = {
                        method: 'Get',
                        headers: myHeaders
                    };

                    fetch(apiProfile + "?UserId=" + userId, requestOptions)
                        .then(response => response.json())
                        .then(result => {  
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: true,
                                    result,
                                },
                            })
                        })
                        .catch(error => {
                        //setShowGeneralError(true)
                        //setMessageError("Hemos tenido un inconveniente, Intente de nuevo en un momento.")
                        setTimeout(() => {
                            //setShowGeneralError(false)
                        }, 10000);
                        })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
