import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const AgentDashboard = Loadable(lazy(() => import("./../../app/components/chat/pages/dashboard/index")));

const authProtectedRoutes = [
  {
    path: "/chat/agent",
    element: <AgentDashboard />
  },
  // {
  //   path: '/',
  //   element: <AgentDashboard />
  // },
]

export default authProtectedRoutes

