import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const DashboardSettings = Loadable(lazy(() => import("./DashboardSettings")));

const dashboardSettingsRoutes = [
    {
        path: '/dashboard/DashboardSettings',
        element: <DashboardSettings />
    }
]

export default dashboardSettingsRoutes
