import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";

import translationGr from './locales/gr/translation.json';
import translationIT from './locales/it/translation.json';
import translationSP from './locales/sp/translation.json';
import translationRS from './locales/rs/translation.json';
import translationENG from './locales/en/translation.json';

//translations
const resources = {
    gr: {
        translation: translationGr
    },
    it: {
        translation: translationIT
    },
    sp: {
        translation: translationSP
    },
    rs: {
        translation: translationRS
    },
    eng: {
        translation: translationENG
    }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'sp', // Establece el idioma predeterminado
    interpolation: {
      escapeValue: false, // No necesitas escapar los valores traducidos
    },
  });

export default i18n;