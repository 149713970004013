import Mock from '../mock'
import jwt from 'jsonwebtoken'
import axios from 'axios.js'
import { apiLogin, apiProfile, apiLogout } from 'app/utils/constantsAPI'

const JWT_SECRET = 'jwt_secret_key'
const JWT_VALIDITY = '7 days'

const userList = [
    {
        id: 1,
        role: 'SA',
        name: 'Developer',
        username: 'developer',
        email: 'jason@ui-lib.com',
        avatar: '/assets/images/face-0.jpg',
        age: 25,
    },
]

// FOLLOWING CODES ARE MOCK SERVER IMPLEMENTATION
// YOU NEED TO BUILD YOUR OWN SERVER
// IF YOU NEED HELP ABOUT SERVER SIDE IMPLEMENTATION
// CONTACT US AT support@ui-lib.com

Mock.onPost('/api/auth/login').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const { email, password} = JSON.parse(config.data)
        //const user = userList.find((u) => u.email === email)

        const response = await axios.post(apiLogin, {
            // email,
            // password,
            "username": email,
            "password": password
        })
        const { user } = response.data

        localStorage.setItem("accessToken", response.data.accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`
        
        if (!user) {
            return [400, { message: 'Email o contraseña invalida' }]
        }

        const accessToken = jwt.sign({ userId: user.id }, JWT_SECRET, {
            expiresIn: JWT_VALIDITY,
        })

        return [
            200,
            {
                accessToken,
                user: {
                    id: user.id,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.name,
                    role: user.role,
                    clientId: user.clientId,
                    clientName: user.clientName,
                    sessionTime: user.sessionTime
                },
            },
        ]
    } catch (err) {
        console.error(err)
        const { statusCode, statusMessage } = err
        if (statusCode === 400) {
            return [400, { message: statusMessage }]
        }
        if (statusCode === 401) {
            return [401, { message: statusMessage }]
        }
        if (statusCode === 403) {
            return [403, { message: statusMessage }]
        }
        if (statusCode === 409) {
            return [409, { message: statusMessage }]
        }
        if (statusCode === 500) {
            return [500, { message: statusMessage }]
        }
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/auth/register').reply((config) => {
    try {
        const { email, username } = JSON.parse(config.data)
        const user = userList.find((u) => u.email === email)

        if (user) {
            return [400, { message: 'User already exists!' }]
        }
        const newUser = {
            id: 2,
            role: 'GUEST',
            name: '',
            username: username,
            email: email,
            avatar: '/assets/images/face-0.jpg',
            age: 25,
        }
        userList.push(newUser)

        const accessToken = jwt.sign({ userId: newUser.id }, JWT_SECRET, {
            expiresIn: JWT_VALIDITY,
        })

        return [
            200,
            {
                accessToken,
                user: {
                    id: newUser.id,
                    avatar: newUser.avatar,
                    email: newUser.email,
                    name: newUser.name,
                    username: newUser.username,
                    role: newUser.role,
                },
            },
        ]
    } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onGet('/api/auth/profile').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const { Authorization } = config.headers
        if (!Authorization) {
            return [401, { message: 'Invalid Authorization token' }]
        }

        const accessToken = Authorization.split(' ')[1]
        const { userId } = jwt.verify(accessToken, JWT_SECRET)
        //const user = userList.find((u) => u.id === userId)
      
        const response = await axios.get(apiProfile, {params: { UserId: userId } })
        const { data } = response.data
        const user = data

        // axios.get('/api/Profile', {params: { UserId: userId } })
        // .then(resp => {
        //     const { data } = resp.data
        //     let user = data
        //     console.log(user)
        //     return [
        //         200,
        //         {
        //             user: {
        //                 id: user.id,
        //                 avatar: user.avatar,
        //                 email: user.email,
        //                 name: user.name,
        //                 role: user.role,
        //             },
        //         },
        //     ]
        // })


        if (!user) {
            return [401, { message: 'Invalid authorization token' }]
        }
       
        return [
            200,
            {
                user: {
                    id: user.id,
                    avatar: user.avatar,
                    email: user.email,
                    name: user.name,
                    role: user.role,
                },
            },
        ]
    } catch (err) {
        console.error(err)
        const { statusCode, statusMessage } = err
        if (statusCode === 400) {
            return [400, { message: statusMessage }]
        }
        if (statusCode === 404) {
            return [404, { message: statusMessage }]
        }
        if (statusCode === 500) {
            return [500, { message: statusMessage }]
        }
        return [500, { message: 'Internal server error' }]
    }
})

Mock.onPost('/api/auth/logout').reply(async (config) => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const { Authorization } = config.headers
        if (!Authorization) {
            return [401, { message: 'Invalid Authorization token' }]
        }

        const accessToken = Authorization.split(' ')[1]
        const { userId } = jwt.verify(accessToken, JWT_SECRET)

        const response = await axios.post(apiLogout, {
            "UserId": userId
        })
        const { statusCode } = response.data

        if (statusCode!== 0) {
            return [400, { successful: false }]
        }
        return [
            200,
            {
                successful: true
            },
        ]
    } catch (err) {
        console.error(err)
        const { statusCode, statusMessage } = err
        if (statusCode === 400) {
            return [400, { message: statusMessage }]
        }
        if (statusCode === 401) {
            return [401, { message: statusMessage }]
        }
        if (statusCode === 403) {
            return [403, { message: statusMessage }]
        }
        if (statusCode === 409) {
            return [409, { message: statusMessage }]
        }
        if (statusCode === 500) {
            return [500, { message: statusMessage }]
        }
        return [500, { message: 'Internal server error' }]
    }
})
