import { array } from "prop-types";
import { apiGetJsonMenu } from 'app/utils/constantsAPI'

// export const navigations = [
//    {
//         name: 'Dashboard',
//         path: '/dashboard/default',
//         icon: 'dashboard',
//     },
//     // {
//     //     label: 'Agente',
//     //     type: 'label',
//     // },
//     // {
//     //     name: 'Dashboard',
//     //     icon: 'call',
//     //     path: "/agente",
//     // },
//     {
//         label: 'Chats',
//         type: 'label',
//     },
//     {
//       name: "Conversaciones",
//       icon: "chat",
//       path: "/conversations/conversations"
//     },
//     // {
//     //   name: "LivePerson Historico",
//     //   icon: "chat",
//     //   path: "/livePersonConversations/ConversationList"
//     // },
//     {
//         label: 'Configuracion',
//         type: 'label',
//     },
//     {
//         name: 'Encuestas',
//         icon: 'poll',
//         path: "/settings/polls",
//     },
//     {
//         name: "IVR's",
//         icon: 'phoneCallback',
//         path: "/settings/ivrMain",
//     },
//     {
//         name: 'Reportes',
//         icon: 'summarize',
//         path: "/reports/reports",
//     },
//     {
//         name: 'Preguntas y respuestas',
//         icon: 'quiz',
//         path: "/qna/qna",
//     },
//     {
//         label: 'Configuracion Plataforma',
//         type: 'label',
//     },
//     {
//         name: 'Configuraciones',
//         icon: 'settings',
//         path: "/platformSettings/platformSettings",
//     },
//     {
//         name: 'Admin Canales',
//         icon: 'hub',
//         path: "/channelsAdmin/channelsAdmin",
//     },


// //     {
// //       name: "IVR's",
// //       icon: "phoneCallback",
// //       children: [
// //       {
// //         name: "IVR",
// //         path: "/settings/ivrMain",
// //         iconText: "IVR"
// //       },
// //       {
// //         name: "Troncales",
// //         path: "/settings/troncals",
// //         iconText: "TC"
// //       }
// //     ]
// //    },

// /*
//     {
//         name: 'Dashboard',
//         path: '/dashboard/default',
//         icon: 'dashboard',
//     },
//     {
//         label: 'PAGES',
//         type: 'label',
//     },
//     {
//         name: 'Session/Auth',
//         icon: 'security',
//         children: [
//             {
//                 name: 'Sign in',
//                 iconText: 'SI',
//                 path: '/session/signin',
//             },
//             {
//                 name: 'Sign up',
//                 iconText: 'SU',
//                 path: '/session/signup',
//             },
//             {
//                 name: 'Forgot Password',
//                 iconText: 'FP',
//                 path: '/session/forgot-password',
//             },
//             {
//                 name: 'Error',
//                 iconText: '404',
//                 path: '/session/404',
//             },
//         ],
//     },
//     {
//         label: 'Components',
//         type: 'label',
//     },
//     {
//         name: 'Components',
//         icon: 'favorite',
//         badge: { value: '30+', color: 'secondary' },
//         children: [
//             {
//                 name: 'Auto Complete',
//                 path: '/material/autocomplete',
//                 iconText: 'A',
//             },
//             {
//                 name: 'Buttons',
//                 path: '/material/buttons',
//                 iconText: 'B',
//             },
//             {
//                 name: 'Checkbox',
//                 path: '/material/checkbox',
//                 iconText: 'C',
//             },
//             {
//                 name: 'Dialog',
//                 path: '/material/dialog',
//                 iconText: 'D',
//             },
//             {
//                 name: 'Expansion Panel',
//                 path: '/material/expansion-panel',
//                 iconText: 'E',
//             },
//             {
//                 name: 'Form',
//                 path: '/material/form',
//                 iconText: 'F',
//             },
//             {
//                 name: 'Icons',
//                 path: '/material/icons',
//                 iconText: 'I',
//             },
//             {
//                 name: 'Menu',
//                 path: '/material/menu',
//                 iconText: 'M',
//             },
//             {
//                 name: 'Progress',
//                 path: '/material/progress',
//                 iconText: 'P',
//             },
//             {
//                 name: 'Radio',
//                 path: '/material/radio',
//                 iconText: 'R',
//             },
//             {
//                 name: 'Switch',
//                 path: '/material/switch',
//                 iconText: 'S',
//             },
//             {
//                 name: 'Slider',
//                 path: '/material/slider',
//                 iconText: 'S',
//             },
//             {
//                 name: 'Snackbar',
//                 path: '/material/snackbar',
//                 iconText: 'S',
//             },
//             {
//                 name: 'Table',
//                 path: '/material/table',
//                 iconText: 'T',
//             },
//         ],
//     },
//     {
//         name: 'Charts',
//         icon: 'trending_up',

//         children: [
//             {
//                 name: 'Echarts',
//                 path: '/charts/echarts',
//                 iconText: 'E',
//             },
//         ],
//     },
//     {
//         name: 'Documentation',
//         icon: 'launch',
//         type: 'extLink',
//         path: 'http://demos.ui-lib.com/matx-react-doc/',
//     },
// */
// ]

export const navigations = [...GetJsonMenu()];

function GetJsonMenu(){
  //console.log("Entro");
  var jsonMenu = [];
  // var myHeaders = new Headers();
  // myHeaders.append("Accept", "application/json");
  // myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));

  // var requestOptions = {
  //   method: 'Get',
  //   headers: myHeaders
  // };

  // fetch(apiGetJsonMenu + "?userId=" + localStorage.getItem("userId"), requestOptions)
  //   .then(response => response.json())
  //   .then(result => {
  //     console.log(result);
  //     jsonMenu = result
  //   })
  //   .catch(error => {
  //     alert("Hemos tenido un inconveniente, Intente de nuevo en un momento.")
  //   })
  return jsonMenu;
}