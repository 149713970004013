import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Analytics = Loadable(lazy(() => import("./Analytics")));
const Agente = Loadable(lazy(() => import("./Agente")));
const SuperUser = Loadable(lazy(() => import("./SuperUser")));
const DashboardAgente = Loadable(lazy(() => import("./DashboardAgente")));
const AgentPauseDetail = Loadable(lazy(() => import("./shared/AgentPauseDetail")));
const DashboardNew = Loadable(lazy(() => import("./NuevoDashboard/DashboardNew")));
const DashboardFeelings = Loadable(lazy(() => import("./NuevoDashboard/DashboardFeelings")));
const DashboardGeneralStatusQueue = Loadable(lazy(() => import("./NuevoDashboard/DashboardGeneralStatusQueue")));
const DashboardGeneralServiceAgents = Loadable(lazy(() => import("./NuevoDashboard/DashboardGeneralServiceAgents")));
const DashboardSettingServiceAgents = Loadable(lazy(() => import("./DashboardSettingServiceAgents")));

const dashboardRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics />
    },
    {
        path: '/dashboard/generaldashboard',
        element: <DashboardNew />
    },
    {
        path: '/dashboard/agente',
        element: <Agente />
    },
    {
        path: '/dashboard/superUser',
        element: <SuperUser />
    },
    {
        path: '/dashboard/DashboardAgente',
        element: <DashboardAgente />
    },
    {
        path: '/dashboard/shared/AgentPauseDetail',
        element: <AgentPauseDetail />
    },
    {
        path: '/dashboard/DashboardFeelings',
        element: <DashboardFeelings />
    },
    {
        path: '/dashboard/DashboardGeneralStatusQueue',
        element: <DashboardGeneralStatusQueue />
    },
    {
        path: '/dashboard/DashboardGeneralServiceAgents',
        element: <DashboardGeneralServiceAgents />
    },
    {
        path: '/dashboard/DashboardSettingServiceAgents',
        element: <DashboardSettingServiceAgents />
    },
]

export default dashboardRoutes
