import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const CreateTemplate = Loadable(lazy(() => import("./CreateTemplates.jsx")));
const SubmitTemplates = Loadable(lazy(() => import("./SubmitTemplates.jsx")));
const CampaignList = Loadable(lazy(() => import("./CampaignList.jsx")));
const SubmitOutboundCampaing = Loadable(lazy(() => import("./SubmitOutboundCampaing.jsx")));

const OutboundCampaignRoutes = [
    {
        path: '/OutboundCampaign/CreateTemplates',
        element: <CreateTemplate />
    },
    {
        path: '/OutboundCampaign/SubmitTemplates',
        element: <SubmitTemplates />
    },
    {
        path: '/OutboundCampaign/CampaignList',
        element: <CampaignList />
    },
    {
        path: '/OutboundCampaign/SubmitOutboundCampaing',
        element: <SubmitOutboundCampaing />
    },
]

export default OutboundCampaignRoutes;

