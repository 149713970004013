import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const PhoneListsExcel = Loadable(lazy(() => import("./PhoneListsExcel.jsx")));
const SubmitList = Loadable(lazy(() => import("./SubmitList.jsx")));
const ListsExcel = Loadable(lazy(() => import("./ListsExcel.jsx")));

const PhoneListRoutes = [
    {
        path: '/list/PhoneListsExcel',
        element: <PhoneListsExcel />
    },
    {
        path: '/list/SubmitList',
        element: <SubmitList />
    },
    {
        path: '/list/ListsExcel',
        element: <ListsExcel />
    },
]

export default PhoneListRoutes;

