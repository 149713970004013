import thunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import RootReducer from './reducers/RootReducer'


/*------------------- import chats ----------------------*/

import createSagaMiddleware from "redux-saga";
import sagas from "./reducers/redux-chat/sagas";

/*----------------- fin import chats -----------------------*/

const sagaMiddleware = createSagaMiddleware(sagas); // chat const
const initialState = {}
const middlewares = [thunk, sagaMiddleware] /*----------sagMiddleware es de chat ---------*/

let devtools = (x) => x

if (
    process.env.NODE_ENV !== 'production' &&
    process.browser &&
    window.__REDUX_DEVTOOLS_EXTENSION__
) {
    devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
}

export const Store = prueba();


function prueba() {
    return createStore(
        RootReducer,
        initialState,
        compose(applyMiddleware(...middlewares), devtools)
    )
}
